<template>
  <div class="container">
    <div class="box flex">
      <div class="left text-left">
        <div class="logo mb20 mt50">
          <img src="../assets/img/nav/logo.png" />
        </div>
        <div class="nav f14">
          <div
            class="item cup flex"
            :data-ds="current"
            :class="current == item.id ? 'active' : ''"
            v-for="(item, index) in list"
            :key="index"
            @click="tap(item)"
          >
            <img :src="current == item.id ? item.img2 : item.img1" />
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="navFooter pl20">
          <div class="title pb15 f12" v-if="user">
            {{ user.store.storeName }}
          </div>
          <!-- <div class="letter">Course licensing</div> -->
        </div>
        <div class="beian">
          <a href="http://beian.miit.gov.cn/" target="_blank">
            粤ICP备2023066121号-6
          </a>
        </div>
      </div>
      <div class="right flex-1">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'home',
  data() {
    return {
      list: [
        {
          name: '我的课程',
          id: 'course',
          img1: require('@/assets/img/nav/nav1.png'),
          img2: require('@/assets/img/nav/nav1-active.png')
        },
        {
          name: '课程素材库',
          id: 'stuff',
          img1: require('@/assets/img/nav/nav2.png'),
          img2: require('@/assets/img/nav/nav2-active.png')
        },
        {
          name: '课程推荐',
          id: 'home',
          img1: require('@/assets/img/nav/nav3.png'),
          img2: require('@/assets/img/nav/nav3-active.png')
        }
        // {name: '我的活动报名', id: 'activity', img1:require('@/assets/img/nav/nav4.png'), img2:require('@/assets/img/nav/nav4-active.png')}
      ],
      current: 'home'
    }
  },
  watch: {
    $route(to) {
      var atrBefore = to.name.split('-')[0]
      this.current = atrBefore
    }
  },
  computed: {
    ...mapState({
      user: state => state.stateUser
    })
  },
  mounted() {
    var atrBefore = this.$route.name.split('-')[0]
    this.current = atrBefore
  },
  methods: {
    tap(item) {
      this.current = item.id
      this.$router.push('/' + item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  min-width: 1280px;
  background-color: #000034;
  .box {
    width: 100%;
    height: 100vh;
    .left {
      position: relative;
      overflow: hidden;
      width: 13.75%;
      min-width: 176px;
      height: 100vh;
      .beian {
        text-align: center;
        color: #cbcbcb;
        font-size: 12px;
        position: absolute;
        bottom: 3%;
        width: 100%;
        a {
          color: #cbcbcb;
          font-size: 12px;
          display: inline;
          &:hover {
            color: #ffffff;
          }
        }
      }
      .navFooter {
        position: absolute;
        bottom: 10%;
        .title {
          color: #ffffff;
        }
        .letter {
          font-size: 14px;
          color: #7a69ae;
        }
      }
      .nav {
        text-align: left;
        .item {
          position: relative;
          padding: 20px 0;
          color: #b2b2c6;
          padding-left: 24%;
          &.active {
            color: #ffffff;
            &::after {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 5px;
              height: 100%;
              background-color: #ffffff;
              border-bottom-right-radius: 5px;
              border-top-right-radius: 5px;
            }
          }
          img {
            vertical-align: middle;
            margin-right: 10px;
          }
        }
      }
      .logo {
        // width: 170px;
        margin: 0 auto;
        padding: 0 4%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .right {
      min-width: 1643px;
      width: 86.25%;
      min-width: 1104px;
      height: 100vh;
      background-color: #ffffff;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }
  }
}
</style>
